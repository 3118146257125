let searchAjax = null;
function  handleLiveSearching(str){
    let route = $("meta[name='search-route']").attr('content');
    $(".live-searching-form .live-searching-result").empty();
    $(".live-searching-form .live-searching-result").css('padding', '0px')
    let loading = `<div class="text-center"><i class="fa fa-spinner fa-pulse  fa-fw"></i> </div>`
    $(".live-searching-form .live-searching-result").html(loading);
    $(".live-searching-form .live-searching-result").css('padding', '.1rem');
    searchAjax = $.ajax({
        url: route,
        method: "post",
        data: {
            q: str,
            _token: $("meta[name='csrf-token']").attr('content')
        },
        success: function (response) {
            let data = '';
            for (const responseElement of response.data.list) {
                data += `<li class="list-group-item border-0 border-bottom-dashed border-bottom-1">
                            <a href="${responseElement.link}">
                            ${responseElement.title}
                            <br>
                            <sub class="text-muted">${responseElement.description}</sub></a>
                            </li>`;
            }
            let view = `<ul class="list-group">${data}</ul>`;
            $(".live-searching-form .live-searching-result").html(view);
            $(".live-searching-form .live-searching-result").css('padding', '.5rem');
        }, error: function (xhr, status, error) {
            var response = JSON.parse(xhr.responseText)
            $(".live-searching-form .live-searching-result").html(response.message);
            console.log('error')
        }
    })
}
/*$('.live-searching-form input').on('blur',function (){
    $('#livesearch .list-group').empty()
    $('#livesearch .container').empty()
    $(".live-searching-form .live-searching-result").css('padding', '0rem');
})*/
$('.live-searching-form input').on('keyup focus', function (e) {
    var str = $(this).val();
    if (!str){
        $('#livesearch .container').empty()
        $(".live-searching-form .live-searching-result").css('padding', '0rem');
        $('#livesearch .list-group').empty()
        return
    }
    handleLiveSearching(str);
})




const currentUrl = window.location.href;

$(document).on('click', '.wishlist', function () {
    let id = $(this).data('id'),
        model = $(this).data('model'),
        url = $(this).data('url'),
        loader = '<i class="fa fa-spinner fa-pulse  fa-fw"></i>',
        btn = this,
        checked = '<i class="fa-solid fs-23px fa-heart text-base  cursor-pointer"></i>',
        unchecked = '<i class="fa-regular fs-23px text-black fa-heart  cursor-pointer"></i>'
    ;
    $(btn).html(loader)
    $.ajax({
        url: url,
        method: 'post',
        data: {
            id: id,
            model: model,
            _token: $("meta[name='csrf-token']").attr('content')
        }, success: function (response) {

            if (response.data.action == 'created') {
                $(btn).html(checked)
            } else {
                $(btn).html(unchecked)
            }
            let removeClass = '#programme_'
            if (model == 'App\\Models\\Video') {
                removeClass = '#wishlist_video_';
            }
            removeClass += id;
            console.log(removeClass)
            console.log(currentUrl)
            if (currentUrl.includes('dashboard/wishlist')) {

                $(removeClass).remove()
            }

        }
    });
})
